<template>
  <div class="sidebar">
    <h4 class="title is-4">Панель управления</h4>
    <b-menu>
      <b-menu-list>
        <b-menu-item
          label="Общая информация"
          icon="info"
          tag="router-link"
          to="/admin/profile"
        ></b-menu-item>
        <b-menu-item
          label="Сотрудники"
          icon="users"
          tag="router-link"
          to="/admin/employees"
        ></b-menu-item>
        <b-menu-item
          label="Статистика"
          icon="chart-line"
          tag="router-link"
          to="/admin/statistic"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
export default {
  name: 'SidebarAdmin',
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.sidebar {
  grid-area: sidebar;
  background-color: $sidebar;
  background: linear-gradient(to top, #204675, #15406c);
  padding: 10px;
  box-shadow: 5px 0 13px 3px hsl(0deg 0% 4% / 10%);

  h4 {
    font-size: 20px;
    color: $white;
    text-align: center;
    font-weight: normal;
  }

  .menu {
    margin-top: 50px;

    .menu-list {
      li {
        a {
          &:hover {
            background-color: #496ca2;
          }

          &.router-link-active {
            background-color: #385788;
          }
        }
      }
    }

    .menu-label,
    .icon-text {
      color: $white;
    }
  }
}
</style>
